import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';






import languageEn from "../locales/languageEn.json"
import languageDe from "../locales/languageDe.json"
import languageEs from "../locales/languageEs.json"

// "Inline" English and Arabic translations. 
// We can localize to any language and any number of languages.

const resources = {
  en: {
    translation: languageEn,
  },
  de: {
    translation: languageDe,
  },
  es:{
    translation: languageEs,
  }
};




i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection:{
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag'],
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        fallbackLng:"en",
        lng:"en",
        // cache user language
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'],
        //cookieMinutes: 10,
        //cookieDomain: 'myDomain'
      }
  });
export default i18next;
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Work(props) {
    const { t } = useTranslation();
    return (

        <section className="section text-center" id="work">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center mx-auto section-main-title">
                <h2 className="section-main-title-text">
                {t("Showroom")}
                    </h2>
                <div className="main-title-border">
                </div>
                <p className="text-muted mx-auto mt-2">
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4 pt-4">
            <ul className="col list-unstyled list-inline mb-0 text-uppercase work_menu" id="menu-filter">
              <li className="list-inline-item">
                <a className="active" data-filter="*">{t("OVERVIEW")}</a>
              </li>
              <li className="list-inline-item">
                <a className="" data-filter=".webdevelopment">{t("WEB")}</a>
              </li>
              <li className="list-inline-item">
                <a className="" data-filter=".desktop">{t("DESKTOP")}</a>
              </li>
              <li className="list-inline-item">
                <a className="" data-filter=".mobile">{t("MOBILE")}</a>
              </li>
              <li className="list-inline-item">
                <a className="" data-filter=".datascience">{t("DATA SCIENCE")}</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 work-filter">
            <div className="col-lg-4 work_item webdevelopment desktop">
              <a href="images/works/1.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/1.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Media Player Software</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 work_item webdevelopment mobile">
              <a href="images/works/2.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/2.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Lingos</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 work_item webdevelopment mobile desktop">
              <a href="images/works/3.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/3.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Edeka ® Baisch - Logistics App & Web Development</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 work_item datascience desktop">
              <a href="images/works/8.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/8.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Computational fluid dynamics - LBM</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 work_item desktop">
              <a href="images/works/5.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/5.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Ashampoo ® ClipFinder HD</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 work_item desktop mobile webdevelopment">
              <a href="images/works/6.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/6.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Ashampoo ® ClipFinder HD 2</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4 work_item webdevelopment">
              <a href="images/works/9.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/9.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Schmidbartl.blue</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            
            <div className="col-lg-4 work_item webdevelopment">
              <a href="images/works/11.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/11.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Mobile Job Companion</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>

            
            <div className="col-lg-4 work_item webdevelopment datascience">
              <a href="images/works/12.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/12.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Mobile Taskspot</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            


           

            
            <div className="col-lg-4 work_item desktop mobile webdevelopment">
              <a href="images/works/7.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/7.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Mobility Management Software</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 work_item datascience desktop">
              <a href="images/works/4.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/4.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Flexible PET system model class</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4 work_item webdevelopment">
              <a href="images/works/10.png" className="img-zoom">
                <div className="work_box">
                  <div className="work_img">
                    <img src="images/works/10.png" className="img-fluid mx-auto d-block rounded" alt="work-img" />
                  </div>
                  <div className="work_detail">
                    <h4 className="mb-0">Virusdedektiv, Backend</h4>
                    <p className="mb-2"></p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
}
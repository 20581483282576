import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Home(props) {
    const { t } = useTranslation();
    return (
        <section className="home-bg section h-100vh" id="home">
        <div id="particles-js">
        </div>
        <div className="home-bg2">
        </div>
        <div className="bg-overlay">
        </div>
        <div className="home-table">
          <div className="home-table-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="text-white text-center">
                    <h4>
                    </h4>
                    <h1 className="header_title mb-0 mt-3">
                      <div id="typed-strings">
                        <b>
                        </b>
                        <b>
                          Full Stack
                                        <br />
                                        Development
                                    </b>
                        <b>
        
                                        Agile Project Management
                                    </b>
                        <b>
                          Continuous Integration
                                    </b>
                        <b>
                          Native App
                                        <br />
                                        Development
                                    </b>
                        <b>
                          Hybrid App
                                        <br />
                                        Development
                                    </b>

                        <b>
                          Progressive
                                        <br />
                                        Web Apps
                                    </b>
                        <b>
                          Cross Platform
                                        <br />
                                        Development
                                    </b>
                        <b>
                          Cloud Computing
                                    </b>
                        <b>
                          Data Science
                                    </b>
                      </div>
                      <span id="typedelement" className="font-weight-bold">
                      </span>
                    </h1>
                    <br />
                    <br />
                    <br />
                    {/* <ul className="social_home list-unstyled text-center pt-4">
                            <li className="list-inline-item"><a href="https://github.com/enjayes" target=”_blank”><i className="mdi mdi-git"></i></a></li> 
                            <li className="list-inline-item"><a href="https://www.linkedin.com/in/schmidbartl/ " target=”_blank”><i className="mdi mdi-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="https://stackoverflow.com/" target=”_blank”><i className="mdi mdi-stackoverflow"></i></a></li>
                            </ul>
                            */}
                    <div className="header_btn">
                      <a href="#contact" className="btn btn-outline-custom btn-rounded mt-4">{t("Contact")}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll_down">
          <a href="#about" className="scroll">
            <i className="mbri-arrow-down text-white"></i>
          </a>
        </div>
      </section>
    )

}
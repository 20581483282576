import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Navbar(props) {
    const { t } = useTranslation();
    return (
        <nav className="navbar navbar-expand-lg fixed-top custom-nav sticky">
        <div className="container">
          <a className="navbar-brand logo" href="index.html">
            <img src="images/logo.png" alt="" className="img-fluid logo-light" />
            <img src="images/logo-dark.png" alt="" className="img-fluid logo-dark" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i className="mdi mdi-menu">
            </i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a href="#home" className="nav-link">{t("Home")}</a>
              </li>
              <li className="nav-item">
                <a href="#about" className="nav-link">{t("About")}</a>
              </li>
              <li className="nav-item">
                <a href="#services" className="nav-link">{t("Services")}</a>
              </li>
              <li className="nav-item">
                <a href="#work" className="nav-link">{t("Showroom")}</a>
              </li>
              <li className="nav-item">
                <a href="#contact" className="nav-link">{t("Contact")}</a>
              </li>
              {/*
              <li className="nav-item">
                <a href="#language" className="nav-link">{t("🌐")}</a>
              </li>
              */}

              
            </ul>
          </div>
        </div>
      </nav>


    )
}
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Services(props) {
    const { t } = useTranslation();
    return (

        <section className="section bg-light" id="services">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center mx-auto section-main-title">
                <h2 className="section-main-title-text">
                {t("Services")}
                  
                    </h2>
                <div className="main-title-border">
                </div>
                <p className="text-muted mx-auto mt-2">
                  {t("Skills, Certifications and Services we offer")}
                 
                    </p>
              </div>
            </div>
          </div>
          <div className="row mt-4 pt-4">
            <div className="col-lg-4">
              <div className="lan_box_ser text-center rounded p-4 mt-3">
                <div className="ser_icon">
                  <i className="mbri-desktop">
                  </i>
                </div>
                <div className="service-content mt-4">
                  <h5 className="font-weight-bold">
                     {t("Full Stack Development")}
                   
                        </h5>
                  <p className="mt-3 text-muted mb-0">
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="lan_box_ser text-center rounded p-4 mt-3">
                <div className="ser_icon">
                  <i className="mbri-touch">
                  </i>
                </div>
                <div className="service-content mt-4">
                  <h5 className="font-weight-bold">
                    {t("Native App Development")}
                 
                        </h5>
                  <p className="mt-3 text-muted mb-0">
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="lan_box_ser text-center rounded p-4 mt-3">
                <div className="ser_icon">
                  <i className="mbri-responsive">
                  </i>
                </div>
                <div className="service-content mt-4">
                  <h5 className="font-weight-bold">
                    {t("Cross Platform Development")} 
                        </h5>
                  <p className="mt-3 text-muted mb-0">
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="lan_box_ser text-center rounded p-4 mt-3">
                <div className="ser_icon">
                  <i className="mbri-timer">
                  </i>
                </div>
                <div className="service-content mt-4">
                  <h5 className="font-weight-bold">
                      {t("Agile Project Management")} 
                   
                        </h5>
                  <p className="mt-3 text-muted mb-0">
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="lan_box_ser text-center rounded p-4 mt-3">
                <div className="ser_icon">
                  <i className="mbri-cloud">
                  </i>
                </div>
                <div className="service-content mt-4">
                  <h5 className="font-weight-bold">
                    {t("Cloud Computing")} 
                   
                        </h5>
                  <p className="mt-3 text-muted mb-0">
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="lan_box_ser text-center rounded p-4 mt-3">
                <div className="ser_icon">
                  <i className="mbri-idea">
                  </i>
                </div>
                <div className="service-content mt-4">
                  <h5 className="font-weight-bold">
                    {t("Data Science")} 
                    
                        </h5>
                  <p className="mt-3 text-muted mb-0">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
import logo from './logo.svg';
import './App.css';

import React, { useEffect, useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next'

import Home from './components/home'
import Navbar from './components/navbar'
import About from './components/about'
import Services from './components/services'
import Work from './components/work'
import Contact from './components/contact'
import Footer from './components/footer'







const loadScript = (id,url,callback) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};




function App() {
 


  useEffect(() => {

      loadScript("jquery", "./js/jquery.min.js",()=>{
      loadScript("jqueryEasing", "./js/jquery.easing.min.js")
      loadScript("magnificPopup", "./js/jquery.magnific-popup.min.js")
      loadScript("scrollspy", "./js/scrollspy.min.js")
      loadScript("carousel", "./js/owl.carousel.min.js")
      loadScript("tingle", "./js/tingle.min.js",()=>{
        loadScript("customScript", "./js/script.js")
        loadScript("customScript2", "./js/custom.js")
      })
      loadScript("popper", "./js/popper.min.js",()=>{
        setTimeout(()=>{
          loadScript("bootstrap", "./js/bootstrap.min.js")
        },1000)
       
      })
      
      loadScript("isotope", "./js/isotope.js")
    })
    loadScript("typed", "./js/typed.js", () => { window.initTyped() })

  
    var thisyear = document.getElementById("thisyear")


    if (thisyear) {
      thisyear.innerHTML = new Date().getFullYear();
    }


    var officeadressElement = document.querySelector("#officeadress")
    var mailadressElement = document.querySelector("#mailadress")
    //var phonenrElement      = document.querySelector("#phonenr")


    var officeadressString = "UGxhdGFuZW5zdHIuIDMzLCA4MjAyNCBUYXVma2lyY2hlbiwgR2VybWFueQ==" //btoa("")
    var mailadressString = "Y29udGFjdEBhZ2lsZXRhaWdhLmNvbQ=="//btoa("")
    var phonenrString = "KzQ5IDE3NiA0NjYgMjMwIDYx"//btoa("")

    if (officeadressElement) {
      officeadressElement.innerHTML = "Munich, Germany"
    }
    if (mailadressElement) {
      mailadressElement.innerHTML = atob(mailadressString)
    }

         //phonenrElement.innerHTML       = atob(phonenrString)
  }
  ,[])


  

  
  

  return (
    <div className="agileTaigaApp">
      <div id="preloader">
        <div id="status">
          <div className="sk-double-bounce">
            <div className="sk-child sk-double-bounce1">
            </div>
            <div className="sk-child sk-double-bounce2">
            </div>
          </div>
        </div>
      </div>

      <Navbar/>
      <Home/>
      <About/>
      <Services/>
      <Work/>
      <Contact/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Footer/>
      

    </div>
  );
}

export default App;

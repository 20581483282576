

import React, { useEffect, useLayoutEffect } from 'react';
import { CustomDialog } from 'react-st-modal';
import i18n from 'i18next';

import { useTranslation } from 'react-i18next'


import LegalNoticeContent from "./legal"



export const getLanguage = () => {
    return i18n.language ||
        (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
        'en';
};


function Footer() {

    const { t, i18n } = useTranslation()


    var selectedLanguageParts = getLanguage()
    selectedLanguageParts = selectedLanguageParts.split("-")
    let selectedLanguage = selectedLanguageParts[0].toLowerCase()
    if (!selectedLanguage) {
        selectedLanguage = "en"
    }

    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang)
        setTimeout(function () {
            var thisyear = document.getElementById("thisyear")
            if (thisyear) {
                thisyear.innerHTML = new Date().getFullYear();
            }
        })
    }


    return (
        <section className="bg-light">
            <div className="container">
                <div className="row pt-4 pb-4">
                    <div className="col-lg-12">
                        <div className="float-left float_none mt-2 mb-2">
                            <p className="copy-rights text-muted mb-0">
                                <span > 2016 - </span>
                                <span id="thisyear">{t("today")}</span>
                                <span> &copy;</span>
                                <span> AgileTaiga</span>

                            </p>

                        </div>
                        <div className="float-left float_none mt-2 mb-2  ml-5 mr-5">
                            <p className="footer-item text-muted mb-0">
                                <span

                                    onClick={async () => {
                                        const result = await CustomDialog(
                                            <LegalNoticeContent />,
                                            {
                                                className: "legal-notice  reset-this",
                                                title: t("Legal Notice"),
                                                showCloseIcon: true,
                                            }
                                        );
                                    }}


                                    className={'legal-notice-href'}>{t("Legal Notice")}</span>
                            </p>

                        </div>

                        <div className="float-right float_none mt-2 mb-2  ml-1">
                            <p className="footer-item text-muted mb-0">
                                <span onClick={function () { changeLanguageHandler("de") }} className={`language-href ${selectedLanguage == "de" ? "language-active" : ""}`} > Deutsch </span>
                                |
                                <span onClick={function () { changeLanguageHandler("es") }} className={`language-href ${selectedLanguage == "es" ? "language-active" : ""}`} > Español </span>
                                |
                                <span onClick={function () { changeLanguageHandler("en") }} className={`language-href ${selectedLanguage == "en" ? "language-active" : ""}`} > English </span>
                            </p>
                        </div>
                        {/*
                            <div className="float-left float_none mt-2 mb-2  ml-5">
                                <ul className="list-inline fot_social mb-0">
                                    
                                        <li className="list-inline-item">
                                            <a href="https://www.linkedin.com/in/schmidbartl/" target="_blank" className="social-icon text-muted"><i className="mdi mdi-linkedin"></i></a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="https://stackoverflow.com/" target="_blank" className="social-icon text-muted"><i className="mdi mdi-xing"></i></a>
                                        </li>
                                        
                                </ul>
                            </div>
                        */}
                       
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Footer;
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";

import { Alert,Confirm} from 'react-st-modal';


export default function Contact(props) {
    const { t } = useTranslation();




    async function  showConfirmation () {
            const result = await Alert( t("Your message has been sent."), t('Thank you'))
        
    }


    const sendMessage = (event) =>{
        //alert("sendMessage")
     

        function ReactIsInDevelomentMode(){ 
            return '_self' in React.createElement('div');
        }

        var url = "mail.php";


        if (ReactIsInDevelomentMode()) {
            url = "http://localhost/agiletaiga/mail.php"
        }


        const XHR = new XMLHttpRequest()
        const formElement = document.querySelector('#contactmail');

        var valid = true
        if (formElement.checkValidity && formElement.checkValidity() == false) {
            valid =false
        }
        if(valid){

            const FD = new FormData(formElement);
 
            function reqListener(event) {
  
                  showConfirmation()

            }

            XHR.addEventListener('load', reqListener
            );


            XHR.addEventListener(' error', function (event) {
                //alert('Oops! Something went wrong.');
            });


            XHR.open('POST',url);

            XHR.send(FD);

     
        }

       
    }



    return (
        <section className="section " id="contact">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="text-center mx-auto section-main-title">
                            <h2 className="section-main-title-text">
                                <span className="font-weight-bold">
                                    {t("Contact")}
                        </span>
                            </h2>
                            <div className="main-title-border">
                            </div>
                            <p className="text-muted mx-auto mt-2">
                                {t("Just ask. Get answers. Your questions and comments are important to us.")}
                               
                    </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 pt-4">
                    <div className="col-lg-4">
                        {/*
                <div>
                <i className="mbri-mobile2 text-custom h2"></i>
                </div>
                <div className="mt-2">
                <p className="mb-0 font-weight-bold">Call Us On</p>
                <p className="text-muted" id="phonenr"></p>
                </div>

                
           
              */}
                        <div className="text-center mt-4">
                            <div>
                                <i className="mbri-letter text-custom h2">
                                </i>
                            </div>
                            <div className="mt-2">
                                <p className="mb-0 font-weight-bold">
                                    
                                    {t("Email Us At")}
                        </p>
                                <p className="text-muted" id="mailadress">
                                </p>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <div>
                                <i className="mbri-pin text-custom h2">
                                </i>
                            </div>
                            <div className="mt-2">
                                <p className="mb-0 font-weight-bold">
                                   
                                    {t("Visit Office")}
                        </p>
                                <p className="text-muted" id="officeadress">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="contact_form">
                            <form id="contactmail">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label htmlFor="name" className="font-weight-bold">
                                            {t("Name")} 
                                    </label>
                                            <input name="name" id="name" type="text" className="form-control" placeholder={t("Your name...")}  
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mt-2">
                                            <label htmlFor="email" className="font-weight-bold">
                                            {t("Email address")} 
                                    </label>
                                            <input name="email" id="email" type="email" className="form-control" placeholder={t("Your email...")} 
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group mt-2">
                                            <label htmlFor="subject" className="font-weight-bold">
                                                
                                                {t("Subject")} 
                                    </label>
                                            <input name="subject" type="text" className="form-control" id="subject" placeholder={t("Your Subject...")}
                                                required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mt-2">
                                            <label htmlFor="comments" className="font-weight-bold">
                                            {t("Message")}
                                    </label>
                                            <textarea name="message" id="comments" rows="4" className="form-control" placeholder={t("Your message...")}
                                                required>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-right">
                                        <input type="submit" className="btn btn-custom" value={t("Send Message")} onClick={(event) => { sendMessage(event)}} /> {/**/} 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        

    )
}
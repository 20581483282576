import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";


export default function About(props) {
    const { t } = useTranslation();
    return (

        <section className="section" id="about">
             <div className="container">
                <div className="row mt-4 pt-4">
                    <div className="col-lg-12">
                        <div className="text-center about-detail mx-auto mt-5">
                            <div className="text-center mx-auto section-main-title">
                                <h2 className="section-main-title-text">
                                {t("Agile Software Development")}
                                </h2>
                                <div className="main-title-border">
                                </div>
                            </div>
                            <br />
                           
                           
                            
                            <br style={{ "clear": "both" }} />
                            <br />
                            <p className="text-muted mt-3">
                                {t("Our Principles")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
             {/*
            <div className="container">
                <div className="row mt-4 pt-4">
                    <div className="col-lg-12">
                        <div className="text-center about-detail mx-auto mt-5">
                            <div className="text-center mx-auto section-main-title">
                                <h2 className="section-main-title-text">
                                    About
                                </h2>
                                <div className="main-title-border">
                                </div>
                            </div>
                            <ul className="mb-0 list-inline text-center about-work">
                                <li className="list-inline-item mr-0 text-muted">
                                    Agile Software Development
                                </li>
                            </ul>
                            <br />
                           
                            <p className="text-muted mt-3">
                                Team
                            </p>
                            <br />
                            <div id="team" style={{ "margin": "auto", "display": "inline-block" }}>
                                <div className="team-member">
                                    <img src="images/teamnsch.png" />
                                    <div className="team-member-text">
                                        <img src="images/teamnschtext.png" />
                                    </div>
                                </div>
                                <div className="team-member">
                                    <img src="images/teammsch.png" />
                                    <div className="team-member-text">
                                        <img src="images/teammschtext.png" />
                                    </div>
                                </div>
                            </div>
                            
                            <br style={{ "clear": "both" }} />
                            <br />
                            <p className="text-muted mt-3">
                                Our Principles
                            </p>
                        </div>
                    </div>
                </div>
            </div>
             */}
            <div className="container">
                <div className="row mt-4 pt-4">
                    <div className="col-lg-4">
                        <div className="lan_box_ser text-center rounded p-4 mt-3">
                            <div className="ser_icon">
                                <i className="mbri-rocket">
                                </i>
                            </div>

                            <div className="service-content mt-4">
                                <h5 className="font-weight-bold">
                                    {t("Fast")}
                                </h5>
                                <p className="mt-3 text-muted mb-0">
                                    
                                    {t("Get things done fast -")}
                                    <br />
                                    {t("We love fast load times, lag free interaction, instant responses.")}
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="lan_box_ser text-center rounded p-4 mt-3">
                            <div className="ser_icon">
                                <i className="mbri-extension">
                                </i>
                            </div>
                            <div className="service-content mt-4">
                                <h5 className="font-weight-bold">
                                    {t("Intuitive")} 
                                </h5>
                                <p className="mt-3 text-muted mb-0">
                                    {t("Intuitive to use - Our users know exactly what to do.")} 
                                   
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="lan_box_ser text-center rounded p-4 mt-3">
                            <div className="ser_icon">
                                <i className="mbri-setting3">
                                </i>
                            </div>
                            <div className="service-content mt-4">
                                <h5 className="font-weight-bold">
                                {t("Agile")} 

                                </h5>
                                <p className="mt-3 text-muted mb-0">
                                 {t("Iterative, incremental and evolutionary - We respond effectively to changing requirements.")} 
                                   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-4">
                        <div className="lan_box_ser text-center rounded p-4 mt-3">
                            <div className="ser_icon">
                                <i className="mbri-success">
                                </i>
                            </div>
                            <div className="service-content mt-4">
                                <h5 className="font-weight-bold">
                                {t("Reliable")} 
                                    
                                </h5>
                                <p className="mt-3 text-muted mb-0">
                                    {t("Creating failure-free software - Our highest priority.")} 
                                   
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="lan_box_ser text-center rounded p-4 mt-3">
                            <div className="ser_icon">
                                <i className="mbri-globe-2">
                                </i>
                            </div>
                            <div className="service-content mt-4">
                                <h5 className="font-weight-bold">
                                    {t("Universal")} 
                                </h5>
                                <p className="mt-3 text-muted mb-0">
                                    {t("Develop once, run anywhere - We love universal software approaches.")} 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="lan_box_ser text-center rounded p-4 mt-3">
                            <div className="ser_icon">
                                <i className="mbri-growing-chart">
                                </i>
                            </div>
                            <div className="service-content mt-4">
                                <h5 className="font-weight-bold">
                                    {t("Optimized")}
                                </h5>
                                <p className="mt-3 text-muted mb-0">
                                    {t("Always match the available resources - We get the most out of it.")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}